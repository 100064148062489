import React from 'react';
import PropTypes from 'prop-types';

export default function AssignmentDetailsView({ assignment }) {
	console.log('assignment', assignment);
	return (
		<div className="w-full h-full flex flex-col justify-start items-start space-y-8">
			{assignment ? (
				<>
					<div className="w-full flex flex-col justify-start items-start space-y-10 mt-10 lg:mt-0">
						<h1 className="text-2xl lg:text-3xl font-semibold">
							{assignment?.assignment_name}
						</h1>
					</div>
					<div className="w-full flex flex-col justify-start items-start space-y-4 h-[90%] overflow-y-scroll">
						<p className="text-md lg:text-xl">
							<span className="font-semibold">Subject:</span>{' '}
							{assignment?.subject_name}
						</p>
						<p>{assignment?.details}</p>
					</div>
				</>
			) : (
				<div className="h-full w-full flex justify-center items-center">
					<p className="text-sm lg:text-md">
						Select an Assignment to see details
					</p>
				</div>
			)}
		</div>
	);
}

AssignmentDetailsView.propTypes = {
	assignment: PropTypes.object.isRequired,
};
