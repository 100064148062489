import React from 'react';
import { Router } from './Router';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
	return (
		<div className="w-screen h-screen font-sans">
			<Router />
			<ToastContainer />
		</div>
	);
}

export default App;
