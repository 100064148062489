import PropTypes from 'prop-types';

export default function EssayCard({ essay }) {
	const { name, school_name, score } = essay;
	return (
		<div className="flex flex-col justify-start items-start space-y-16 w-full bg-green-100 text-green-500 flex-grow p-4 rounded-[0.75rem]">
			<p className="text-2xl">{name}</p>
			<div className="flex flex-col justify-start items-start space-y-1">
				<p className="text-lg">{school_name}</p>
				<p className="text-lg">Score: {score}</p>
			</div>
		</div>
	);
}

EssayCard.propTypes = {
	essay: PropTypes.shape({
		name: PropTypes.string.isRequired,
		school_name: PropTypes.string.isRequired,
		score: PropTypes.number.isRequired,
	}).isRequired,
};
