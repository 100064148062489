import PropTypes from 'prop-types';

export default function TaskCard({ task }) {
	const { name, description, type, created_at, due_date } = task;
	return (
		<div className="flex flex-col justify-start items-start space-y-16 w-full bg-orange-100 text-orange-800 flex-grow p-4 rounded-[0.75rem]">
			<div className="flex flex-col justify-start items-start space-y-1">
				<p className="text-2xl">{name}</p>
				<p className="text-md">Description: {description}</p>
			</div>
			<div className="flex flex-col justify-start items-start space-y-1">
				<p className="text-md">Task Type: {type}</p>
				<p className="text-md">Creation Date: {created_at}</p>
				<p className="text-md">Due Date: {due_date}</p>
			</div>
		</div>
	);
}

TaskCard.propTypes = {
	task: PropTypes.shape({
		name: PropTypes.string.isRequired,
		description: PropTypes.string.isRequired,
		type: PropTypes.string.isRequired,
		created_at: PropTypes.string.isRequired,
		due_date: PropTypes.string.isRequired,
	}).isRequired,
};
