import React from 'react';
import SignUpInfo from '../sign-up/signup.info';
import useResetPassword from '../../hooks/useResetPassword';
import ResetPasswordHeader from './rp.header';
import ResetPasswordForm from './rp.form';
import { useLocation } from 'react-router-dom';

export function ResetPasswordView() {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const token = queryParams.get('token');
	const { formik, isSubmitting, isVerifyingToken } = useResetPassword(token);

	return (
		<div className="w-full h-full flex lg:flex-row justify-center  items-center">
			<div className="hidden lg:block w-1/2 h-full">
				<SignUpInfo />
			</div>
			<div className="lg:w-1/2 md:w-2/3 w-full  px-4 flex flex-col justify-start items-center p-10">
				<ResetPasswordHeader />
				<ResetPasswordForm
					formik={formik}
					isLoggingIn={isSubmitting}
					isVerifyingToken={isVerifyingToken}
				/>
			</div>
		</div>
	);
}
