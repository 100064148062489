import * as Yup from 'yup';

const form2InitialValues = {
	nationality: '',
	field_of_study: '',
	current_level: '',
	highest_level: '',
};

const form2ValidationSchema = Yup.object({
	nationality: Yup.string().required('This field is required'),
	field_of_study: Yup.string().required('This field is required'),
	current_level: Yup.string().required('This field is required'),
	highest_level: Yup.string().required('This field is required'),
});

const form2Errors = {
	nationality: '',
	field_of_study: '',
	current_level: '',
	highest_level: '',
};

const form2Touched = {
	nationality: false,
	field_of_study: false,
	current_level: false,
	highest_level: false,
};

export { form2InitialValues, form2ValidationSchema, form2Errors, form2Touched };
