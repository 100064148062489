import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { resetPassword, validateToken } from '../services/authentication';

const useResetPassword = (token) => {
	const navigate = useNavigate();
	const [resetToken, setResetToken] = useState(null);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [isVerifyingToken, setIsVerifyingToken] = useState(false);

	const formik = useFormik({
		initialValues: {
			password: '',
			password_confirmation: '',
		},
		validationSchema: Yup.object({
			password: Yup.string().required('Password is required'),
			password_confirmation: Yup.string()
				.oneOf([Yup.ref('password'), null], 'Passwords must match')
				.required('Password confirmation is required'),
		}),
		onSubmit: (values) => {
			setIsSubmitting(true);
			resetPassword(resetToken, values.password)
				.then(() => {
					toast.success('Password reset successful');
					navigate('/sign-in');
				})
				.catch((error) => {
					toast.error(error?.response?.data?.message || 'An error occurred');
				})
				.finally(() => setIsSubmitting(false));
		},
	});

	useEffect(() => {
		setIsVerifyingToken(true);
		const toastId = toast.loading('Verifying token link...');
		validateToken(token)
			.then((r) => {
				setResetToken(token);
				toast.update(toastId, {
					render: 'Token verified successfully. Proceed to set a new password',
					type: 'success',
					isLoading: false,
					autoClose: 5000,
				});
			})
			.catch(() => {
				toast.update(toastId, {
					render:
						'Invalid or expired token. Kindly request a new password reset link.',
					type: 'error',
					isLoading: false,
					autoClose: 5000,
				});
			})
			.finally(() => setIsVerifyingToken(false));
	}, [token]);

	return {
		formik,
		isSubmitting,
		isVerifyingToken,
	};
};

export default useResetPassword;
