import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { resetPasswordForLoggedInUser } from '../services/authentication';
import { useAuth } from '../context/authentication';

const useResetPasswordLoggedInUser = () => {
	const navigate = useNavigate();
	const { user } = useAuth();
	const [isSubmitting, setIsSubmitting] = useState(false);

	const formik = useFormik({
		initialValues: {
			new_password: '',
			old_password: '',
		},
		validationSchema: Yup.object({
			new_password: Yup.string().required('New password is required'),
			old_password: Yup.string().required('Old password is required'),
		}),
		onSubmit: (values) => {
			setIsSubmitting(true);
			resetPasswordForLoggedInUser(
				user?.username,
				values.new_password,
				values.old_password
			)
				.then(() => {
					formik.resetForm();
					toast.success('Password reset successful');
					navigate('/settings');
				})
				.catch((error) => {
					toast.error(error?.response?.data?.message || 'An error occurred');
				})
				.finally(() => setIsSubmitting(false));
		},
	});

	return {
		formik,
		isSubmitting,
	};
};

export default useResetPasswordLoggedInUser;
