export default function SignInInfo() {
	return (
		<div className="w-full h-screen flex flex-col justify-center items-center text-left bg-purple-950 p-10 text-white space-y-[2rem] relative">
			<div className="absolute inset-0 overflow-hidden z-0">
				<div className="bg-purple-400  w-10 h-10  absolute top-10 left-16 transform rotate-45"></div>
				<div className="bg-yellow-500 opacity-70 w-16 h-16 rounded-lg absolute top-20 right-24 transform rotate-12"></div>
				<div className="bg-purple-200 opacity-60 w-6 h-6 rounded-md absolute bottom-24 left-8 transform rotate-6"></div>
				<div className="bg-purple-600 opacity-50 w-12 h-12 rounded-lg absolute bottom-10 right-16 transform rotate-45"></div>
				<div className="bg-purple-400 opacity-60 w-8 h-8 rounded-full absolute top-40 left-40 transform rotate-12"></div>
				<div className="bg-white opacity-60 w-8 h-8 rounded-full absolute bottom-40 right-40 transform rotate-12"></div>
				<div className="bg-yellow-200 opacity-60 w-8 h-8 rounded-full absolute bottom-10 left-40 transform rotate-12"></div>
				<div className="bg-purple-400 w-10 h-10 rounded-lg absolute top-[22rem] right-16 transform rotate-45"></div>
				<div className="bg-white opacity-60 w-6 h-6 rounded-md absolute top-[22rem] left-16 transform rotate-6"></div>
			</div>
			<div className="flex flex-row justify-start items-center space-x-4 w-full max-w-md">
				<p className="size-[3.5rem] border-[6px] border-purple-300 rounded-full text-purple-300 text-4xl font-extrabold flex justify-center items-center">
					a
				</p>
				<h1 className="text-6xl text-purple-300 font-bold">amiva</h1>
			</div>
			<p className="text-4xl text-purple-300 mb-4 max-w-md leading-[3rem]">
				Where opportunities search for{' '}
				<span className="text-yellow-100">you</span>
			</p>
			<p className="text-sm max-w-md text-purple-300">
				Connect with mentors, apply for universities and learn from our wealth
				of resources all in one hub
			</p>
		</div>
	);
}
