import PropTypes from 'prop-types';
import Button from '../common/Button';
import InputField from '../common/form/InputField';

export default function ResetPasswordForm({
	formik,
	isSubmitting,
	isVerifyingToken,
}) {
	return (
		<div className="py-10 w-full lg:max-w-sm flex flex-col justify-start items-center space-y-[1rem]">
			<InputField
				disabled={isVerifyingToken}
				label="New Password"
				id="password"
				type="password"
				error={formik.errors.password}
				touched={formik.touched.password}
				value={formik.values.password}
				onChange={formik.handleChange}
				onBlur={formik.handleBlur}
			/>
			<InputField
				disabled={isVerifyingToken}
				label="Confrim Password"
				id="password_confirmation"
				type="password"
				error={formik.errors.password_confirmation}
				touched={formik.touched.password_confirmation}
				value={formik.values.password_confirmation}
				onChange={formik.handleChange}
				onBlur={formik.handleBlur}
			/>
			<div className="py-4 w-full">
				<Button
					disabled={isSubmitting || isVerifyingToken}
					onClick={formik.handleSubmit}
				>
					{isSubmitting ? 'Resetting...' : 'Reset Password'}
				</Button>
			</div>
		</div>
	);
}

ResetPasswordForm.propTypes = {
	formik: PropTypes.object.isRequired,
	isLoggingIn: PropTypes.bool.isRequired,
};
