import { useEffect, useState } from 'react';
import data from '../components/students/student.data';

const useStudents = () => {
	const [essays, setEssays] = useState(data.essay);
	const [tasks, setTasks] = useState(data.tasks);
	const [records, setRecords] = useState(data.records);
	const [showRightPanel, setShowRightPanel] = useState(false);
	const [limitEssays, setLimitEssays] = useState(6);
	const [limitTasks, setLimitTasks] = useState(6);
	const [limitRecords, setLimitRecords] = useState(20);

	const handleLimitEssays = () => {
		if (limitEssays === 6) {
			setLimitEssays(essays.length);
			setEssays(data.essay);
		} else {
			setLimitEssays(6);
			setEssays(data.essay.slice(0, 6));
		}
	};

	const handleLimitTasks = () => {
		if (limitTasks === 6) {
			setLimitTasks(tasks.length);
			setTasks(data.tasks);
		} else {
			setLimitTasks(6);
			setTasks(data.tasks.slice(0, 6));
		}
	};

	const handleLimitRecords = () => {
		if (limitRecords === 20) {
			setLimitRecords(records.length);
			setRecords(data.records);
		} else {
			setLimitRecords(6);
			setRecords(data.records.slice(0, 6));
		}
	};

	useEffect(() => {
		const newEssays = data.essay.slice(0, limitEssays);
		setEssays(newEssays);

		const newTasks = data.tasks.slice(0, limitTasks);
		setTasks(newTasks);

		const newRecords = data.records.slice(0, limitRecords);
		setRecords(newRecords);
	}, [limitEssays, limitTasks, limitRecords]);

	return {
		showRightPanel,
		setShowRightPanel,
		handleLimitEssays,
		essays,
		tasks,
		handleLimitTasks,
		records,
		handleLimitRecords,
	};
};

export default useStudents;
