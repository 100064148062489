import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { useAuth } from '../context/authentication';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const useSignIn = () => {
	const navigate = useNavigate();
	const { loginUser } = useAuth();
	const [isLoggingIn, setIsLoggingIn] = useState(false);

	const formik = useFormik({
		initialValues: {
			email: '',
			password: '',
		},
		validationSchema: Yup.object({
			email: Yup.string()
				.email('Invalid email address')
				.required('This field is required'),
			password: Yup.string().required('This field is required'),
		}),
		onSubmit: (values) => {
			setIsLoggingIn(true);
			loginUser(values.email, values.password)
				.then(() => {
					setIsLoggingIn(false);
					navigate('/');
				})
				.catch((error) => {
					setIsLoggingIn(false);
					toast.error(error?.response?.data?.message || 'An error occurred');
				});
		},
	});

	return {
		formik,
		isLoggingIn,
	};
};

export default useSignIn;
