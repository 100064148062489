import LayoutView from '../layout/layout.view';
import DashboardHeader from './dashboard.header';
import useDashboard from '../../hooks/useDashboard';
import DashboardScorecards from './dashboard.scorecards';
import DashboardPanel from './dashboard.panel';
import PanelToggle from '../common/PanelToggle';
import useLayout from '../../hooks/useLayout';

export default function DashboardView() {
	const { showSidebar } = useLayout();
	const { data, updateTodo, setShowRightPanel, showRightPanel } =
		useDashboard();
	return (
		<LayoutView>
			<div className="flex flex-row w-full justify-start items-start h-full">
				{showSidebar === false && (
					<PanelToggle toggle={setShowRightPanel} state={showRightPanel} />
				)}
				<div className="w-full h-full p-4 lg:p-10 mt-10 lg:mt-0 flex flex-col justify-start items-start space-y-10 overflow-y-scroll">
					<DashboardHeader />
					<DashboardScorecards data={data} />
				</div>
				<DashboardPanel
					data={data}
					updateTodo={updateTodo}
					showRightPanel={showRightPanel}
					setShowRightPanel={setShowRightPanel}
				/>
			</div>
		</LayoutView>
	);
}
