import PropTypes from 'prop-types';

export default function RandomAvatar({ size }) {
	const avatarUrl = `https://gravatar.com/avatar?d=retro&s=${size}`;

	return <img src={avatarUrl} alt="Random Avatar" width={size} height={size} />;
}

RandomAvatar.propTypes = {
	size: PropTypes.number,
};

RandomAvatar.defaultProps = {
	size: 50,
};
