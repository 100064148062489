import axios from 'axios';
import { customAxios } from '../utils/custom-axios';

const BASE_URL = process.env.REACT_APP_BACKEND_URL;

// Fetch user information
export const getUserInfo = async (userID) => {
	try {
		return await customAxios.get(`${BASE_URL}/users/${userID}`);
	} catch (error) {
		throw error;
	}
};

// Create user information
export const createBulkUserInfo = async (userID, userInfo, token) => {
	try {
		return await axios.post(
			`${BASE_URL}/users/${userID}/user-info/bulk`,
			userInfo,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);
	} catch (error) {
		throw error;
	}
};
