/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

export default function TextInputField({
	label,
	id,
	error,
	touched,
	...props
}) {
	return (
		<div className="w-full flex flex-col justify-start items-start group">
			<label
				htmlFor={id}
				className="text-[0.75rem] font-medium text-purple-300 group-focus-within:text-purple-900 mb-2"
			>
				{label}
			</label>
			<div
				className={`w-full flex flex-col justify-start items-start px-[1rem] h-fit py-[0.56rem] space-y-[0.37rem] rounded-[0.5rem] ${touched && error ? 'border-red-500 focus-within:ring-[0px] border-[2px] ' : 'border-[1px] group-focus-within:ring-[1px] group-focus-within:ring-purple-900 border-purple-300'}  focus-within:outline-none `}
			>
				<textarea
					rows={4}
					id={id}
					className="w-full h-full text-[0.875rem] font-normal placeholder-[#566370]  border-none outline-none resize-none"
					{...props}
				/>
			</div>
			{error && touched && (
				<p className="text-xs text-red-500 font-normal text-left w-full pt-1.5">
					{error}
				</p>
			)}
		</div>
	);
}

TextInputField.defaultProps = {
	error: null,
	touched: false,
};

TextInputField.propTypes = {
	label: PropTypes.string.isRequired,
	id: PropTypes.string.isRequired,
	error: PropTypes.string,
	touched: PropTypes.bool,
};
