import { useState } from 'react';

const useLayout = () => {
	const [showSidebar, setShowSidebar] = useState(false);

	return {
		showSidebar,
		setShowSidebar,
	};
};

export default useLayout;
