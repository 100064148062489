import { Link } from 'react-router-dom';

export default function SignInHeader() {
	return (
		<div className="w-full flex flex-col justify-start items-center space-y-4">
			<h1 className="text-purple-900 text-5xl text-center font-semibold mb-8">
				Sign In
			</h1>
			<p className="text-xl text-purple-900">
				Create an account{' '}
				<Link to="/sign-up" className="text-yellow-400">
					here
				</Link>
			</p>
			<p className="text-slate-400 text-2xl">Or</p>
			<p className="text-xl text-purple-900">Sign In to Your Account</p>
		</div>
	);
}
