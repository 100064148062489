import PropTypes from 'prop-types';
import { STEPS } from './signup.resources';

export default function SignUpHeader({ currentStep, setCurrentStep, formik }) {
	return (
		<div className="w-full flex flex-col justify-start items-center space-y-4 pt-16">
			<h1 className="text-purple-900 text-4xl text-center font-semibold">
				Create an Account
			</h1>
			<div className="flex flex-row justify-center items-center space-x-2">
				{STEPS.map((s) => (
					<button
						key={s.step}
						type="button"
						onClick={() => {
							if (s.step < currentStep) {
								setCurrentStep(s.step);
							} else {
								formik.handleSubmit();
							}
						}}
						className={`size-[0.75rem] rounded-full ${s.step === currentStep ? 'bg-purple-900' : 'border-[1px] border-purple-900'}`}
					/>
				))}
			</div>
		</div>
	);
}

SignUpHeader.propTypes = {
	currentStep: PropTypes.number.isRequired,
	setCurrentStep: PropTypes.func.isRequired,
	formik: PropTypes.object.isRequired,
};
