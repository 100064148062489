import PropTypes from 'prop-types';
import TaskCard from './task.card';

export default function TasksCards({ handleLimitTasks, tasks }) {
	return (
		<div className="w-full h-1/2 flex flex-col justify-start items-start space-y-8 ">
			<div className="w-full flex flex-row justify-between items-start">
				<h1 className="text-2xl lg:text-3xl d font-semibold">Assign Tasks</h1>
				{tasks.length >= 6 && (
					<button
						onClick={handleLimitTasks}
						className="text-orange-800 text-xl"
					>
						{tasks.length === 6 ? 'See All' : 'See Less'}
					</button>
				)}
			</div>
			<div className="grid grid-cols-1 lg:grid-cols-3  gap-[2.5rem] w-full overflow-y-scroll">
				{tasks?.map((task, index) => (
					<TaskCard key={index} task={task} />
				))}
			</div>
		</div>
	);
}

TasksCards.propTypes = {
	handleLimitTasks: PropTypes.func.isRequired,
	tasks: PropTypes.array.isRequired,
};
