import * as Yup from 'yup';
import {
	AFFIRMATION_DATA,
	APPLICATION_STATUS_CERTAINTY,
} from '../signup.resources';

const form4InitialValues = {
	nature_of_internet: '',
	applied_abroad: AFFIRMATION_DATA[0].value,
	application_knowledge: APPLICATION_STATUS_CERTAINTY[0].value,
	need_help_in_application: '',
};

const form4ValidationSchema = Yup.object({
	nature_of_internet: Yup.string().required('This field is required'),
	applied_abroad: Yup.string().required('This field is required'),
	application_knowledge: Yup.string().required('This field is required'),
	need_help_in_application: Yup.string().required('This field is required'),
});

const form4Errors = {
	nature_of_internet: '',
	applied_abroad: '',
	application_knowledge: '',
	need_help_in_application: '',
};

const form4Touched = {
	nature_of_internet: false,
	applied_abroad: false,
	application_knowledge: false,
	need_help_in_application: false,
};

export { form4InitialValues, form4ValidationSchema, form4Errors, form4Touched };
