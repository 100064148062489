import { Link } from 'react-router-dom';

export default function NavItem({ nav, currentRoute }) {
	const { title, route, activeIcon, icon } = nav;
	return (
		<Link
			to={route}
			className={`flex flex-row justify-start items-center space-x-6 w-full max-w-xs rounded-lg p-[0.75rem] ${currentRoute === route ? 'bg-purple-400 text-white' : 'bg-white text-black'}`}
		>
			{currentRoute === route ? activeIcon : icon}
			<p className="text-xl">{title}</p>
		</Link>
	);
}
