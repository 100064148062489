import React from 'react';
import useForgotPassword from '../../hooks/useForgetPassword';
import ForgotPasswordHeader from './fp.header';
import SignUpInfo from '../sign-up/signup.info';
import ForgotPasswordForm from './fp.form';

export function ForgotPasswordView() {
	const { formik, isSubmitting } = useForgotPassword();

	return (
		<div className="w-full h-full flex lg:flex-row justify-center  items-center">
			<div className="hidden lg:block w-1/2 h-full">
				<SignUpInfo />
			</div>
			<div className="lg:w-1/2 md:w-2/3 w-full  px-4 flex flex-col justify-start items-center p-10">
				<ForgotPasswordHeader />
				<ForgotPasswordForm formik={formik} isLoggingIn={isSubmitting} />
			</div>
		</div>
	);
}
