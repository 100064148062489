import PropTypes from 'prop-types';

export default function TodoItem({ todo, updateTodo }) {
	const { id, title, priority, due, status } = todo;
	return (
		<div className="w-full flex flex-row justify-start items-center space-x-6 text-purple-600">
			<input
				type="checkbox"
				className="accent-purple-400 size-[1.5rem] bg-purple-950"
				checked={status === 'completed'}
				onChange={() => updateTodo(id)}
			/>
			<div className="flex flex-col justify-start items-start">
				<p
					className={`text-md text-left ${status === 'completed' && 'line-through'}`}
				>
					{title}
				</p>
				<div className="flex flex-row justify-start space-x-3 divide-x-[1px] divide-purple-600">
					<p
						className={`text-md text-left ${status === 'completed' && 'line-through'}`}
					>
						Priority {priority}
					</p>
					<p
						className={`text-md text-left pl-2 ${status === 'completed' && 'line-through'}`}
					>
						{due}
					</p>
				</div>
			</div>
		</div>
	);
}

TodoItem.propTypes = {
	todo: PropTypes.object.isRequired,
	updateTodo: PropTypes.func.isRequired,
};
