import PropTypes from 'prop-types';
import Button from '../../common/Button';
import TextInputField from '../../common/form/TextInputField';
import SelectInputField from '../../common/form/SelectInputField';
import {
	AFFIRMATION_DATA,
	APPLICATION_STATUS_CERTAINTY,
} from '../signup.resources';

export default function SignUpForm4({ formik, isSubmitting }) {
	return (
		<div className="py-10 w-full lg:max-w-sm flex flex-col justify-start items-center space-y-[1rem]">
			<TextInputField
				label="Describe the nature of your main internet connection"
				id="nature_of_internet"
				error={formik.errors.nature_of_internet}
				touched={formik.touched.nature_of_internet}
				value={formik.values.nature_of_internet}
				onChange={formik.handleChange}
				onBlur={formik.handleBlur}
			/>
			<SelectInputField
				options={AFFIRMATION_DATA}
				label="Have you applied to study abroad before?"
				id="applied_abroad"
				error={formik.errors.applied_abroad}
				touched={formik.touched.applied_abroad}
				value={formik.values.applied_abroad}
				onChange={formik.handleChange}
				onBlur={formik.handleBlur}
			/>
			<SelectInputField
				options={APPLICATION_STATUS_CERTAINTY}
				label="How well do you know the application process?"
				id="application_knowledge"
				error={formik.errors.application_knowledge}
				touched={formik.touched.application_knowledge}
				value={formik.values.application_knowledge}
				onChange={formik.handleChange}
				onBlur={formik.handleBlur}
			/>
			<TextInputField
				label="Do you need help any area of the application process?[Explain]"
				id="need_help_in_application"
				error={formik.errors.need_help_in_application}
				touched={formik.touched.need_help_in_application}
				value={formik.values.need_help_in_application}
				onChange={formik.handleChange}
				onBlur={formik.handleBlur}
			/>
			<div className="py-4 w-full">
				<Button disabled={isSubmitting} onClick={formik.handleSubmit}>
					{isSubmitting ? 'Submitting...' : 'Submit'}
				</Button>
			</div>
		</div>
	);
}

SignUpForm4.propTypes = {
	formik: PropTypes.object.isRequired,
	isSubmitting: PropTypes.bool.isRequired,
};
