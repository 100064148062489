import EssayCard from './essay.card';
import PropTypes from 'prop-types';

export default function EssayCards({ handleLimitEssays, essays }) {
	return (
		<div className="w-full h-1/2 flex flex-col justify-start items-start space-y-8">
			<div className="w-full flex flex-row justify-between items-start">
				<h1 className="text-xl lg:text-2xl font-semibold">Essay Reviews</h1>
				{essays.length >= 6 && (
					<button
						onClick={handleLimitEssays}
						className="text-green-500 text-xl"
					>
						{essays.length === 6 ? 'See All' : 'See Less'}
					</button>
				)}
			</div>
			<div className="grid grid-cols-1 lg:grid-cols-3 gap-[2.5rem] w-full overflow-y-scroll">
				{essays?.map((essay, index) => (
					<EssayCard key={index} essay={essay} />
				))}
			</div>
		</div>
	);
}

EssayCards.propTypes = {
	handleLimitEssays: PropTypes.func.isRequired,
	essays: PropTypes.array.isRequired,
};
