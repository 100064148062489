import { useLocation } from 'react-router-dom';
import { IoMdClose } from 'react-icons/io';
import PropTypes from 'prop-types';
import NAV_ITEMS from '../layout.resources';
import NavItem from './menu.item';
import MenuBrand from './menu.brand';
import MenuLogout from './menu.logout';
import { useAuth } from '../../../context/authentication';

export default function MobileMenu({ setShowSidebar, showSidebar }) {
	const { user } = useAuth();
	const { pathname } = useLocation();
	const currentRoute = pathname;

	return (
		<div
			className={`lg:hidden flex flex-col justify-between items-center space-y-12 w-full h-full bg-white p-10  sidebar ${showSidebar ? 'show ' : ''}`}
		>
			<div className="w-full flex flex-col justify-start items-start space-y-[2.5rem]">
				<div
					className={`w-full flex ${showSidebar ? 'flex-row justify-between' : 'justify-center'}`}
				>
					<MenuBrand />
					<button
						className="lg:hidden flex justify-center items-center size-[2.5rem] rounded-full bg-white p-1"
						onClick={() => setShowSidebar(false)}
					>
						<IoMdClose size="1.75rem" color="#3B0764" />
					</button>
				</div>
				<nav className="w-full flex flex-col justify-start items-start space-y-[1.5rem]">
					{NAV_ITEMS.filter((item) => item.role.includes(user?.role)).map(
						(nav) => (
							<NavItem key={nav.id} nav={nav} currentRoute={currentRoute} />
						)
					)}
				</nav>
			</div>
			<MenuLogout />
		</div>
	);
}

MobileMenu.propTypes = {
	setShowSidebar: PropTypes.func.isRequired,
	showSidebar: PropTypes.bool.isRequired,
};
