import axios from 'axios';
import CryptoJS from 'crypto-js';

const BASE_URL = process.env.REACT_APP_NEO_BACKEND_URL;
const TOKEN_SECRET = process.env.REACT_APP_TOKEN_SECRET;

export function encryptUserToken(token) {
	return CryptoJS.AES.encrypt(token, TOKEN_SECRET).toString();
}

export function decodeUserToken(cipher) {
	try {
		const bytes = CryptoJS.AES.decrypt(cipher, TOKEN_SECRET);
		return bytes.toString(CryptoJS.enc.Utf8);
	} catch (error) {
		console.error('Failed to decode user token:', error);
		return null;
	}
}

export const customAxios = axios.create({
	baseURL: BASE_URL,
	withCredentials: false,
});

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
	failedQueue.forEach((prom) => {
		if (error) {
			prom.reject(error);
		} else {
			prom.resolve(token);
		}
	});

	failedQueue = [];
};

const refreshToken = async () => {
	const refreshToken = localStorage.getItem('refreshToken');
	if (!refreshToken) {
		throw new Error('No refresh token available');
	}

	const response = await axios.post(`${BASE_URL}/auth/refresh`, {
		token: decodeUserToken(refreshToken),
	});
	localStorage.setItem('accessToken', encryptUserToken(response.data.jwt));
	localStorage.setItem(
		'refreshToken',
		encryptUserToken(response.data.refreshToken)
	);
	return response.data.accessToken;
};

customAxios.interceptors.request.use((config) => {
	const token = localStorage.getItem('accessToken');
	if (token) {
		const setup = { ...config };
		setup.headers.Authorization = `Bearer ${decodeUserToken(token)}`;
		return setup;
	}
	return config;
});

customAxios.interceptors.response.use(
	(response) => response,
	async (error) => {
		const originalRequest = error.config;

		if (error.response?.status === 401 && !originalRequest._retry) {
			if (isRefreshing) {
				return new Promise((resolve, reject) => {
					failedQueue.push({ resolve, reject });
				})
					.then((token) => {
						originalRequest.headers.Authorization = `Bearer ${token}`;
						return customAxios(originalRequest);
					})
					.catch((err) => {
						return Promise.reject(err);
					});
			}

			originalRequest._retry = true;
			isRefreshing = true;

			return new Promise((resolve, reject) => {
				refreshToken()
					.then((newToken) => {
						customAxios.defaults.headers.common.Authorization = `Bearer ${newToken}`;
						originalRequest.headers.Authorization = `Bearer ${newToken}`;
						processQueue(null, newToken);
						resolve(customAxios(originalRequest));
					})
					.catch((err) => {
						processQueue(err, null);
						window.localStorage.clear();
						window.sessionStorage.clear();
						window.location.replace('/sign-in');
						reject(err);
					})
					.finally(() => {
						isRefreshing = false;
					});
			});
		}

		return Promise.reject(error);
	}
);
