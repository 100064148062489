import Search from '../common/Search';
import { IoMdNotifications } from 'react-icons/io';
import { useAuth } from '../../context/authentication';

export default function DashboardHeader() {
	const { user } = useAuth();
	return (
		<div className="flex lg:flex-row flex-col justify-between space-y-8 lg:space-y-0 items-center w-full">
			<div className="flex flex-col items-center lg:items-start space-y-4">
				<h1 className="text-4xl font-semibold">{`${user?.firstName} ${user?.lastName}`}</h1>
				<p className="text-sm">Welcome {user?.firstName}!</p>
			</div>
			<div className="flex flex-row items-center space-x-4 w-full lg:w-[50%]">
				<Search placeholder="Search here" />
				<IoMdNotifications size="2rem" />
			</div>
		</div>
	);
}
