import PropTypes from 'prop-types';
import InputField from '../../common/form/InputField';
import Button from '../../common/Button';
import PhoneInputField from '../../common/form/PhoneInputField';

export default function SignUpForm1({ formik }) {
	return (
		<div className="py-10 w-full lg:max-w-sm flex flex-col justify-start items-center space-y-[1rem]">
			<InputField
				label="Name"
				id="name"
				error={formik.errors.name}
				touched={formik.touched.name}
				value={formik.values.name}
				onChange={formik.handleChange}
				onBlur={formik.handleBlur}
			/>
			<InputField
				label="Date of Birth"
				id="dob"
				error={formik.errors.dob}
				touched={formik.touched.dob}
				value={formik.values.dob}
				type="date"
				onChange={formik.handleChange}
				onBlur={formik.handleBlur}
			/>
			<InputField
				label="Email Address"
				id="email"
				error={formik.errors.email}
				touched={formik.touched.email}
				value={formik.values.email}
				onChange={formik.handleChange}
				onBlur={formik.handleBlur}
			/>
			<PhoneInputField formik={formik} />
			<InputField
				label="Password"
				id="password"
				type="password"
				error={formik.errors.password}
				touched={formik.touched.password}
				value={formik.values.password}
				onChange={formik.handleChange}
				onBlur={formik.handleBlur}
			/>
			<InputField
				label="Re-enter Password"
				id="password_confirmation"
				type="password"
				error={formik.errors.password_confirmation}
				onChange={formik.handleChange}
				onBlur={formik.handleBlur}
				touched={formik.touched.password_confirmation}
				value={formik.values.password_confirmation}
			/>
			<div className="py-4 w-full">
				<Button onClick={formik.handleSubmit}>Next</Button>
			</div>
		</div>
	);
}

SignUpForm1.propTypes = {
	formik: PropTypes.object.isRequired,
};
