/* eslint-disable no-undef,react-hooks/exhaustive-deps */
import { createContext, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { login, register } from '../services/authentication';
import { useNavigate } from 'react-router-dom';

const AuthenticationContext = createContext(null);

const useAuth = () => {
	const context = useContext(AuthenticationContext);
	if (!context) {
		throw new Error('useAuth must be used within an AuthenticationProvider');
	}
	return context;
};

function AuthenticationProvider({ children }) {
	const navigate = useNavigate();
	const [user, setUser] = useState(() => {
		const savedUser = localStorage.getItem('user');
		return savedUser ? JSON.parse(savedUser) : null;
	});

	// Register User account function
	const registerUser = async (userData, userType) => {
		try {
			return await register(userData, userType);
		} catch (error) {
			throw error;
		}
	};

	// Login User account function
	const loginUser = async (username, password) => {
		try {
			const response = await login(username, password);
			if (response?.status !== 200) {
				throw new Error('Login failed');
			}
			localStorage.setItem('user', JSON.stringify(response?.data?.user));
			setUser(response?.data?.user);
			return response;
		} catch (error) {
			throw error;
		}
	};

	const logoutUser = () => {
		localStorage.clear();
		navigate('/sign-in');
	};

	// Memoize the context value to avoid unnecessary re-renders
	const value = useMemo(
		() => ({
			user,
			registerUser,
			loginUser,
			logoutUser,
		}),
		[user]
	);

	return (
		<AuthenticationContext.Provider value={value}>
			{children}
		</AuthenticationContext.Provider>
	);
}

export { useAuth, AuthenticationProvider };

AuthenticationProvider.propTypes = {
	children: PropTypes.node.isRequired,
};
