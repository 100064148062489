import PropTypes from 'prop-types';

export default function StudentsTable({ records }) {
	return (
		<table className="w-full bg-white">
			<thead>
				<tr>
					<th className="py-2 px-4 border-b border-gray-200 bg-gray-100 text-center text-sm leading-4 font-medium text-gray-600 capitalize tracking-wider">
						Student Name
					</th>
					<th className="py-2 px-4 border-b border-gray-200 bg-gray-100 text-center text-sm leading-4 font-medium text-gray-600 capitalize tracking-wider">
						Application Prog.
					</th>
					<th className="py-2 px-4 border-b border-gray-200 bg-gray-100 text-center text-sm leading-4 font-medium text-gray-600 capitalize tracking-wider">
						Coursework Avg.
					</th>
				</tr>
			</thead>
			<tbody>
				{records.map((record, index) => (
					<tr
						key={index}
						className={index % 2 === 0 ? 'bg-blue-200' : 'bg-blue-50'}
					>
						<td className="p-4 border-b border-gray-200 text-center">
							{record.name}
						</td>
						<td className="p-4border-b border-gray-200 text-center">
							{record.progress}%
						</td>
						<td className="p-4 border-b border-gray-200 text-center">
							{record.average}
						</td>
					</tr>
				))}
			</tbody>
		</table>
	);
}

StudentsTable.propTypes = {
	records: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string.isRequired,
			progress: PropTypes.number.isRequired,
			average: PropTypes.number.isRequired,
		})
	).isRequired,
};
