import DATA from '../components/dashboard/data.json';
import { useState } from 'react';

const useDashboard = () => {
	const [data, setData] = useState(DATA);
	const [showRightPanel, setShowRightPanel] = useState(false);

	const updateTodo = (id) => {
		const updatedTodos = data.todos.map((todo) =>
			todo.id === id ? { ...todo, completed: !todo.completed } : todo
		);
		setData({ ...data, todos: updatedTodos });
	};

	return { data, updateTodo, showRightPanel, setShowRightPanel };
};

export default useDashboard;
