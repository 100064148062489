import PropTypes from 'prop-types';

export default function ActivityItem({ activity }) {
	const { student_name, assignment_name, marked, submitted_at } = activity;
	return (
		<div className="flex flex-col justify-start items-start space-y-4 w-full h-fit p-4 rounded-[0.75rem]">
			<p className="text-2xl font-medium text-purple-300">{student_name}</p>
			<div className="flex flex-col justify-start items-start space-y-2">
				<p className="text-xl">{assignment_name}</p>
				<div className="flex flex-row justify-start items-center space-x-3 divide-x-[2px] divide-black">
					<p
						className={`text-sm lg:text-md ${marked ? 'text-green-400' : 'text-red-300'}`}
					>
						{marked ? 'Marked' : 'Unmarked'}
					</p>
					<p className="text-sm lg:text-md pl-2">
						Submitted At: {submitted_at}
					</p>
				</div>
			</div>
		</div>
	);
}

ActivityItem.propTypes = {
	activity: PropTypes.shape({
		student_name: PropTypes.string.isRequired,
		assignment_name: PropTypes.string.isRequired,
		marked: PropTypes.bool.isRequired,
		submitted_at: PropTypes.string.isRequired,
	}).isRequired,
};
