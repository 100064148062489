import PropTypes from 'prop-types';

export default function RightPanel({ children, show, widthClassname }) {
	return (
		<div
			className={`flex flex-col justify-between items-center space-y-4 w-full ${widthClassname} h-full p-4 lg:p-10  transition-transform duration-300 ${
				show ? 'translate-x-0' : 'translate-x-full'
			} lg:translate-x-0 fixed lg:static top-0 right-0 bg-white  lg:drop-shadow-2xl`}
		>
			{children}
		</div>
	);
}

RightPanel.defaultProps = {
	widthClassname: 'lg:w-[35%]',
};

RightPanel.propTypes = {
	children: PropTypes.node.isRequired,
	show: PropTypes.bool.isRequired,
	widthClassname: PropTypes.string,
};
