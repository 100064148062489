import PropTypes from 'prop-types';
import InputField from '../common/form/InputField';
import Button from '../common/Button';
import React from 'react';

export default function SettingsResetPassword({ formik, isSubmitting }) {
	return (
		<div className="w-full lg:max-w-screen-md flex justify-start items-center">
			<div className="py-10 w-full flex flex-col justify-start items-start space-y-[1.5rem]">
				<h1 className="text-purple-900 text-2xl text-left font-semibold">
					Reset Password
				</h1>
				<InputField
					label="Old Password"
					id="old_password"
					type="password"
					error={formik.errors.old_password}
					touched={formik.touched.old_password}
					value={formik.values.old_password}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
				/>
				<InputField
					label="New Password"
					id="new_password"
					type="password"
					error={formik.errors.new_password}
					touched={formik.touched.new_password}
					value={formik.values.new_password}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
				/>
				<div className="py-4 w-full">
					<Button disabled={isSubmitting} onClick={formik.handleSubmit}>
						{isSubmitting ? 'Resetting...' : 'Reset Password'}
					</Button>
				</div>
			</div>
		</div>
	);
}

SettingsResetPassword.propTypes = {
	formik: PropTypes.object.isRequired,
	isSubmitting: PropTypes.bool.isRequired,
};
