import SignInInfo from './signin.info';
import SignInHeader from './signin.header';
import SignInForm from './signin.form';
import useSignIn from '../../hooks/useSignIn';

export function SignInView() {
	const { isLoggingIn, formik } = useSignIn();

	return (
		<div className="w-full h-full flex lg:flex-row justify-center  items-center">
			<div className="hidden lg:block w-1/2 h-full">
				<SignInInfo />
			</div>
			<div className="lg:w-1/2 md:w-2/3 w-full  px-4 flex flex-col justify-start items-center p-10">
				<SignInHeader />
				<SignInForm formik={formik} isLoggingIn={isLoggingIn} />
			</div>
		</div>
	);
}
