import PropTypes from 'prop-types';
import RightPanel from '../common/RightPanel';
import ActivityItems from './activities/activity.items';
import { useAuth } from '../../context/authentication';
import ROLES from '../../utils/user-roles';
import AssignmentDetailsView from './assignment-details/ad.view';

export default function CoursesPanel({ showRightPanel, selectedAssignment }) {
	const { user } = useAuth();
	return (
		<RightPanel show={showRightPanel}>
			{user?.role === ROLES[0] ? (
				<ActivityItems assignment={selectedAssignment} />
			) : (
				<AssignmentDetailsView assignment={selectedAssignment} />
			)}
		</RightPanel>
	);
}

CoursesPanel.propTypes = {
	showRightPanel: PropTypes.bool.isRequired,
	activities: PropTypes.array.isRequired,
	selectedAssignment: PropTypes.object.isRequired,
};
