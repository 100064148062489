import PropTypes from 'prop-types';
import RightPanel from '../common/RightPanel';
import StudentsTable from './students.table';

export default function StudentsPanel({
	showRightPanel,
	records,
	handleLimitRecords,
}) {
	return (
		<RightPanel show={showRightPanel} widthClassname="lg:w-[50%]">
			<div className="w-full flex flex-col justify-start items-start space-y-8 h-full mt-10 lg:mt-0">
				<div className="w-full flex flex-row justify-between items-start">
					<h1 className="text-2xl lg:text-3xl d font-semibold">Student List</h1>
					{records.length >= 20 && (
						<button onClick={handleLimitRecords} className=" text-xl">
							See all
						</button>
					)}
				</div>
				<div className="h-[98vh] overflow-y-scroll overflow-hidden w-full">
					<StudentsTable records={records} />
				</div>
			</div>
		</RightPanel>
	);
}

StudentsPanel.propTypes = {
	showRightPanel: PropTypes.bool.isRequired,
	records: PropTypes.array.isRequired,
	handleLimitRecords: PropTypes.func.isRequired,
};
