import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { useState } from 'react';
import { forgetPassword } from '../services/authentication';

const useForgotPassword = () => {
	const [isSubmitting, setIsSubmitting] = useState(false);

	const formik = useFormik({
		initialValues: {
			email: '',
		},
		validationSchema: Yup.object({
			email: Yup.string()
				.email('Invalid email address')
				.required('This field is required'),
		}),
		onSubmit: (values) => {
			setIsSubmitting(true);
			forgetPassword(values.email)
				.then(() => {
					formik.resetForm();
					toast.success('Password reset link sent to your email');
				})
				.catch((error) => {
					toast.error(error?.response?.data?.message || 'An error occurred');
				})
				.finally(() => setIsSubmitting(false));
		},
	});

	return {
		formik,
		isSubmitting,
	};
};

export default useForgotPassword;
