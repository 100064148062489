import { GiHamburgerMenu } from 'react-icons/gi';
import PropTypes from 'prop-types';

export default function MenuToggle({ setShowSidebar }) {
	return (
		<button className="fixed lg:hidden flex justify-center items-center size-[2.5rem] top-2 left-2 z-10 rounded-full  p-1 ">
			<GiHamburgerMenu
				color="#3B0764"
				size="1.75rem"
				onClick={() => setShowSidebar(true)}
			/>
		</button>
	);
}

MenuToggle.propTypes = {
	setShowSidebar: PropTypes.func.isRequired,
};
