/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

export default function PhoneInputField({ formik }) {
	return (
		<div className="w-full flex flex-col justify-start items-start group">
			<label
				htmlFor="phone_number"
				className="text-[0.75rem] font-medium text-purple-300 group-focus-within:text-purple-900 mb-2"
			>
				Phone Number
			</label>
			<div
				id="phone_number"
				className={`w-full flex flex-col justify-start items-start px-[1rem] h-[3.25rem] py-[0.56rem] space-y-[0.37rem] rounded-[0.5rem] ${formik.errors.phone_number && formik.touched.phone_number ? 'border-red-500 focus-within:ring-[0px] border-[2px] ' : 'border-[1px] group-focus-within:ring-[1px] group-focus-within:ring-purple-900 border-purple-300'}  focus-within:outline-none `}
			>
				<PhoneInput
					international
					containerClass="border-none w-full"
					inputClass={`text-[0.875rem]  h-full font-medium w-full px-[1rem] focus:px-[1rem] focus-within:text-[#6455EC] border-none outline-none`}
					defaultCountry="KE"
					value={formik.values.phone_number}
					onChange={(value) =>
						formik.setValues({ ...formik.values, phone_number: value })
					}
					onBlur={() => {
						const phoneNumber = formik.values.phone_number;
						const isValid = isPossiblePhoneNumber(phoneNumber);

						if (phoneNumber && phoneNumber.length > 0 && isValid) {
							formik.setErrors({ ...formik.errors, phone_number: '' });
						} else if (isValid === false) {
							formik.setErrors({
								...formik.errors,
								phone_number: 'Please provide a valid phone number',
							});
						}
					}}
				/>
			</div>
			{formik.errors.phone_number && formik.touched.phone_number && (
				<p className="text-xs text-red-500 font-normal text-left w-full pt-1.5">
					{formik.errors.phone_number}
				</p>
			)}
		</div>
	);
}

PhoneInputField.propTypes = {
	formik: PropTypes.object.isRequired,
};
