import * as Yup from 'yup';
import moment from 'moment';

// Get today's date in DD/MM/YYYY format
const today = moment().format('YYYY-MM-DD');

const form1InitialValues = {
	name: '',
	dob: today,
	email: '',
	phone_number: '',
	password: '',
	password_confirmation: '',
};

const form1ValidationSchema = Yup.object({
	name: Yup.string().required('Name is required'),
	dob: Yup.date()
		.max(new Date(), 'Date of Birth cannot be in the future')
		.required('Date of Birth is required'),
	email: Yup.string()
		.email('Invalid email address')
		.required('Email is required'),
	phone_number: Yup.string().required('Phone number is required'),
	password: Yup.string().required('Password is required'),
	password_confirmation: Yup.string()
		.oneOf([Yup.ref('password'), null], 'Passwords must match')
		.required('Password confirmation is required'),
});

const form1Errors = {
	name: '',
	dob: '',
	email: '',
	phone_number: '',
	password: '',
	password_confirmation: '',
};

const form1Touched = {
	name: false,
	dob: false,
	email: false,
	phone_number: false,
	password: false,
	password_confirmation: false,
};

export { form1InitialValues, form1ValidationSchema, form1Errors, form1Touched };
