import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

export default function ProtectedRoute({ children }) {
	const token = localStorage.getItem('accessToken');
	const location = useLocation();
	return !token ? (
		<Navigate to="/sign-in" state={{ from: location }} replace />
	) : (
		children
	);
}

ProtectedRoute.propTypes = {
	children: PropTypes.node.isRequired,
};
