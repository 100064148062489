import MobileMenu from './menu.mobile';
import DesktopMenu from './menu.desktop';
import PropTypes from 'prop-types';

export default function MenuView({ setShowSidebar, showSidebar }) {
	return (
		<>
			<MobileMenu setShowSidebar={setShowSidebar} showSidebar={showSidebar} />
			<DesktopMenu />
		</>
	);
}

MenuView.propTypes = {
	setShowSidebar: PropTypes.func.isRequired,
	showSidebar: PropTypes.bool.isRequired,
};
