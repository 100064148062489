import TodoItem from './todo.item';
import PropTypes from 'prop-types';

export default function TodoItems({ todos, updateTodo }) {
	return (
		<div className="w-full flex flex-col justify-start items-start space-y-4 border-t-[1px] border-slate-400 py-3 max-h-[50vh] overflow-y-scroll">
			<p className="text-2xl text-left font-semibold">To-do List</p>
			{todos.map((todo) => (
				<TodoItem todo={todo} key={todo.id} updateTodo={updateTodo} />
			))}
		</div>
	);
}

TodoItems.propTypes = {
	todos: PropTypes.array.isRequired,
};
