const data = {
	assignments: [
		{
			id: '1',
			assignment_name: 'Assignment 1',
			subject_name: 'Mathematics',
			details:
				'This assignment requires a comprehensive understanding of algebraic concepts. Include an introduction, detailed analysis, and conclusion.',
			total_marks: 100,
			created_at: '01/01/2021',
			expires_at: '01/02/2021',
			activities: [
				{
					student_name: 'Student 1',
					assignment_name: 'Assignment 1',
					marked: true,
					submitted_at: '01/01/2021',
				},
				{
					student_name: 'Student 2',
					assignment_name: 'Assignment 1',
					marked: false,
					submitted_at: '02/01/2021',
				},
			],
		},
		{
			id: '2',
			assignment_name: 'Assignment 2',
			subject_name: 'Science',
			details:
				'This assignment covers basic physics concepts. Include an introduction, main points, and visual aids like charts.',
			total_marks: 100,
			created_at: '02/01/2021',
			expires_at: '02/02/2021',
			activities: [
				{
					student_name: 'Student 1',
					assignment_name: 'Assignment 2',
					marked: true,
					submitted_at: '02/01/2021',
				},
				{
					student_name: 'Student 3',
					assignment_name: 'Assignment 2',
					marked: false,
					submitted_at: '03/01/2021',
				},
			],
		},
		{
			id: '3',
			assignment_name: 'Assignment 3',
			subject_name: 'History',
			details:
				'This assignment explores World War II. Discuss key battles and strategies used.',
			total_marks: 100,
			created_at: '03/01/2021',
			expires_at: '03/02/2021',
			activities: [
				{
					student_name: 'Student 4',
					assignment_name: 'Assignment 3',
					marked: true,
					submitted_at: '04/01/2021',
				},
				{
					student_name: 'Student 5',
					assignment_name: 'Assignment 3',
					marked: false,
					submitted_at: '05/01/2021',
				},
			],
		},
		{
			id: '4',
			assignment_name: 'Assignment 4',
			subject_name: 'Geography',
			details:
				'Discuss the impact of climate change on global weather patterns.',
			total_marks: 100,
			created_at: '04/01/2021',
			expires_at: '04/02/2021',
			activities: [
				{
					student_name: 'Student 1',
					assignment_name: 'Assignment 4',
					marked: true,
					submitted_at: '05/01/2021',
				},
				{
					student_name: 'Student 6',
					assignment_name: 'Assignment 4',
					marked: false,
					submitted_at: '06/01/2021',
				},
			],
		},
		{
			id: '5',
			assignment_name: 'Assignment 5',
			subject_name: 'English',
			details: "Write an essay analyzing the themes in Shakespeare's Hamlet.",
			total_marks: 100,
			created_at: '05/01/2021',
			expires_at: '05/02/2021',
			activities: [
				{
					student_name: 'Student 2',
					assignment_name: 'Assignment 5',
					marked: true,
					submitted_at: '06/01/2021',
				},
				{
					student_name: 'Student 4',
					assignment_name: 'Assignment 5',
					marked: false,
					submitted_at: '07/01/2021',
				},
			],
		},
		{
			id: '6',
			assignment_name: 'Assignment 6',
			subject_name: 'Chemistry',
			details: 'Discuss the properties and reactions of acids and bases.',
			total_marks: 100,
			created_at: '06/01/2021',
			expires_at: '06/02/2021',
			activities: [
				{
					student_name: 'Student 3',
					assignment_name: 'Assignment 6',
					marked: true,
					submitted_at: '08/01/2021',
				},
				{
					student_name: 'Student 5',
					assignment_name: 'Assignment 6',
					marked: false,
					submitted_at: '09/01/2021',
				},
			],
		},
		{
			id: '7',
			assignment_name: 'Assignment 7',
			subject_name: 'Biology',
			details:
				'Explain the process of photosynthesis and its importance to the ecosystem.',
			total_marks: 100,
			created_at: '07/01/2021',
			expires_at: '07/02/2021',
			activities: [
				{
					student_name: 'Student 2',
					assignment_name: 'Assignment 7',
					marked: true,
					submitted_at: '08/01/2021',
				},
				{
					student_name: 'Student 4',
					assignment_name: 'Assignment 7',
					marked: false,
					submitted_at: '09/01/2021',
				},
			],
		},
		{
			id: '8',
			assignment_name: 'Assignment 8',
			subject_name: 'Computer Science',
			details: 'Write a program to implement a basic calculator.',
			total_marks: 100,
			created_at: '08/01/2021',
			expires_at: '08/02/2021',
			activities: [
				{
					student_name: 'Student 1',
					assignment_name: 'Assignment 8',
					marked: true,
					submitted_at: '09/01/2021',
				},
				{
					student_name: 'Student 5',
					assignment_name: 'Assignment 8',
					marked: false,
					submitted_at: '10/01/2021',
				},
			],
		},
		{
			id: '9',
			assignment_name: 'Assignment 1',
			subject_name: 'Mathematics',
			details:
				'This assignment requires a comprehensive understanding of algebraic concepts. Include an introduction, detailed analysis, and conclusion.',
			total_marks: 100,
			created_at: '01/01/2021',
			expires_at: '01/02/2021',
			activities: [
				{
					student_name: 'Student 1',
					assignment_name: 'Assignment 1',
					marked: true,
					submitted_at: '01/01/2021',
				},
				{
					student_name: 'Student 2',
					assignment_name: 'Assignment 1',
					marked: false,
					submitted_at: '02/01/2021',
				},
			],
		},
		{
			id: '10',
			assignment_name: 'Assignment 2',
			subject_name: 'Science',
			details:
				'This assignment covers basic physics concepts. Include an introduction, main points, and visual aids like charts.',
			total_marks: 100,
			created_at: '02/01/2021',
			expires_at: '02/02/2021',
			activities: [
				{
					student_name: 'Student 1',
					assignment_name: 'Assignment 2',
					marked: true,
					submitted_at: '02/01/2021',
				},
				{
					student_name: 'Student 3',
					assignment_name: 'Assignment 2',
					marked: false,
					submitted_at: '03/01/2021',
				},
			],
		},
		{
			id: '11',
			assignment_name: 'Assignment 3',
			subject_name: 'History',
			details:
				'This assignment explores World War II. Discuss key battles and strategies used.',
			total_marks: 100,
			created_at: '03/01/2021',
			expires_at: '03/02/2021',
			activities: [
				{
					student_name: 'Student 4',
					assignment_name: 'Assignment 3',
					marked: true,
					submitted_at: '04/01/2021',
				},
				{
					student_name: 'Student 5',
					assignment_name: 'Assignment 3',
					marked: false,
					submitted_at: '05/01/2021',
				},
			],
		},
		{
			id: '12',
			assignment_name: 'Assignment 4',
			subject_name: 'Geography',
			details:
				'Discuss the impact of climate change on global weather patterns.',
			total_marks: 100,
			created_at: '04/01/2021',
			expires_at: '04/02/2021',
			activities: [
				{
					student_name: 'Student 1',
					assignment_name: 'Assignment 4',
					marked: true,
					submitted_at: '05/01/2021',
				},
				{
					student_name: 'Student 6',
					assignment_name: 'Assignment 4',
					marked: false,
					submitted_at: '06/01/2021',
				},
			],
		},
		{
			id: '13',
			assignment_name: 'Assignment 5',
			subject_name: 'English',
			details: "Write an essay analyzing the themes in Shakespeare's Hamlet.",
			total_marks: 100,
			created_at: '05/01/2021',
			expires_at: '05/02/2021',
			activities: [
				{
					student_name: 'Student 2',
					assignment_name: 'Assignment 5',
					marked: true,
					submitted_at: '06/01/2021',
				},
				{
					student_name: 'Student 4',
					assignment_name: 'Assignment 5',
					marked: false,
					submitted_at: '07/01/2021',
				},
			],
		},
		{
			id: '14',
			assignment_name: 'Assignment 6',
			subject_name: 'Chemistry',
			details: 'Discuss the properties and reactions of acids and bases.',
			total_marks: 100,
			created_at: '06/01/2021',
			expires_at: '06/02/2021',
			activities: [
				{
					student_name: 'Student 3',
					assignment_name: 'Assignment 6',
					marked: true,
					submitted_at: '08/01/2021',
				},
				{
					student_name: 'Student 5',
					assignment_name: 'Assignment 6',
					marked: false,
					submitted_at: '09/01/2021',
				},
			],
		},
		{
			id: '15',
			assignment_name: 'Assignment 7',
			subject_name: 'Biology',
			details:
				'Explain the process of photosynthesis and its importance to the ecosystem.',
			total_marks: 100,
			created_at: '07/01/2021',
			expires_at: '07/02/2021',
			activities: [
				{
					student_name: 'Student 2',
					assignment_name: 'Assignment 7',
					marked: true,
					submitted_at: '08/01/2021',
				},
				{
					student_name: 'Student 4',
					assignment_name: 'Assignment 7',
					marked: false,
					submitted_at: '09/01/2021',
				},
			],
		},
		{
			id: '16',
			assignment_name: 'Assignment 8',
			subject_name: 'Computer Science',
			details: 'Write a program to implement a basic calculator.',
			total_marks: 100,
			created_at: '08/01/2021',
			expires_at: '08/02/2021',
			activities: [
				{
					student_name: 'Student 1',
					assignment_name: 'Assignment 8',
					marked: true,
					submitted_at: '09/01/2021',
				},
				{
					student_name: 'Student 5',
					assignment_name: 'Assignment 8',
					marked: false,
					submitted_at: '10/01/2021',
				},
			],
		},
	],
};

export default data;
