import PropTypes from 'prop-types';
import DashboardAvatar from './dashboard.avatar';
import TodoItems from '../todo/todo.items';
import RightPanel from '../common/RightPanel';

export default function DashboardPanel({ data, updateTodo, showRightPanel }) {
	return (
		<RightPanel show={showRightPanel}>
			<DashboardAvatar />
			<TodoItems todos={data.todos} updateTodo={updateTodo} />
		</RightPanel>
	);
}

DashboardPanel.propTypes = {
	data: PropTypes.object.isRequired,
	showRightPanel: PropTypes.bool.isRequired,
};
