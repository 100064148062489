import PropTypes from 'prop-types';
import { IoMdClose } from 'react-icons/io';
import { TbCircleChevronLeft } from 'react-icons/tb';

export default function PanelToggle({ toggle, state }) {
	return (
		<button
			onClick={() => toggle(!state)}
			className="fixed lg:hidden flex justify-center items-center size-[2.5rem] top-2 right-2 z-10 rounded-full  p-1 "
		>
			{!state ? (
				<TbCircleChevronLeft color="#3B0764" size="2.5rem" />
			) : (
				<IoMdClose size="2.5rem" color="#3B0764" />
			)}
		</button>
	);
}

PanelToggle.propTypes = {
	toggle: PropTypes.func.isRequired,
	state: PropTypes.bool.isRequired,
};
