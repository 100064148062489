export default function MenuBrand() {
	return (
		<div className="flex flex-row justify-center items-center space-x-2">
			<p className="size-[2.5rem] border-[6px] border-purple-900 rounded-full text-purple-900 text-2xl font-extrabold flex justify-center items-center">
				a
			</p>
			<h1 className="text-4xl text-purple-900 font-bold">amiva</h1>
		</div>
	);
}
