import PropTypes from 'prop-types';
import Search from '../../common/Search';
import ActivityItem from './activity.item';
import React from 'react';

export default function ActivityItems({ assignment }) {
	return (
		<div className="w-full h-full">
			{assignment ? (
				<div className="w-full h-full flex flex-col space-y-8 mt-10 lg:mt-0">
					<Search placeholder="Search here" />
					<h1 className="text-2xl lg:text-3xl font-semibold">
						Student Activities
					</h1>
					{assignment.activities?.length > 0 ? (
						<div className="w-full flex flex-col space-y-4 h-[90%] overflow-y-scroll">
							{assignment.activities.map((activity, index) => (
								<ActivityItem key={index} activity={activity} />
							))}
						</div>
					) : (
						<div className="h-full w-full flex justify-center items-center">
							<p className="text-sm lg:text-md">
								No student activities found for the selected assignment
							</p>
						</div>
					)}
				</div>
			) : (
				<div className="h-full w-full flex justify-center items-center">
					<p className="text-sm lg:text-md">
						Select an Assignment to view students activities
					</p>
				</div>
			)}
		</div>
	);
}

ActivityItems.propTypes = {
	assignment: PropTypes.object.isRequired,
};
