import { FiLogOut } from 'react-icons/fi';
import { useAuth } from '../../../context/authentication';

export default function MenuLogout() {
	const { logoutUser } = useAuth();
	return (
		<button
			onClick={logoutUser}
			className="flex flex-row justify-start items-center space-x-6 w-full max-w-xs rounded-lg p-[0.75rem]"
		>
			<FiLogOut size="1.75rem" />
			<p className="text-xl">Log out</p>
		</button>
	);
}
