import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

export default function NoAuthRoute({ children }) {
	const token = localStorage.getItem('accessToken');
	const location = useLocation();
	return token ? (
		<Navigate to="/" state={{ from: location }} replace />
	) : (
		children
	);
}

NoAuthRoute.propTypes = {
	children: PropTypes.node.isRequired,
};
