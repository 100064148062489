import * as Yup from 'yup';
import moment from 'moment/moment';

const today = moment().format('YYYY-MM-DD');

const form3InitialValues = {
	name_of_high_school: '',
	high_school_graduation_date: today,
	high_school_grade: '',
	school_prospect: '',
};

const form3ValidationSchema = Yup.object({
	name_of_high_school: Yup.string().required('This field is required'),
	high_school_graduation_date: Yup.date()
		.max(new Date(), 'Date of graduation can not be in the future')
		.required('This field is required'),
	high_school_grade: Yup.string().required('This field is required'),
	school_prospect: Yup.string().required('This field is required'),
});

const form3Errors = {
	name_of_high_school: '',
	high_school_graduation_date: '',
	high_school_grade: '',
	school_prospect: '',
};

const form3Touched = {
	name_of_high_school: false,
	high_school_graduation_date: false,
	high_school_grade: false,
	school_prospect: false,
};

export { form3InitialValues, form3ValidationSchema, form3Errors, form3Touched };
