import useLayout from '../../hooks/useLayout';
import MenuView from './Menu/menu.view';
import MenuToggle from './Menu/menu.toggle';

export default function LayoutView({ children }) {
	const { showSidebar, setShowSidebar } = useLayout();
	return (
		<main className="w-screen h-screen flex flex-row justify-start">
			<MenuToggle setShowSidebar={setShowSidebar} />
			<MenuView setShowSidebar={setShowSidebar} showSidebar={showSidebar} />
			<div className="w-full h-full bg-purple-50">{children}</div>
		</main>
	);
}
