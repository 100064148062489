import PropTypes from 'prop-types';
import InputField from '../../common/form/InputField';
import Button from '../../common/Button';

export default function SignUpForm3({ formik }) {
	return (
		<div className="py-10 w-full lg:max-w-sm flex flex-col justify-start items-center space-y-[1rem]">
			<InputField
				label="Name of High School"
				id="name_of_high_school"
				error={formik.errors.name_of_high_school}
				touched={formik.touched.name_of_high_school}
				value={formik.values.name_of_high_school}
				onChange={formik.handleChange}
				onBlur={formik.handleBlur}
			/>
			<InputField
				label="High school graduation date"
				id="high_school_graduation_date"
				error={formik.errors.high_school_graduation_date}
				touched={formik.touched.high_school_graduation_date}
				value={formik.values.high_school_graduation_date}
				type="date"
				onChange={formik.handleChange}
				onBlur={formik.handleBlur}
			/>
			<InputField
				label="Final/aniticipated High school grade"
				id="high_school_grade"
				error={formik.errors.high_school_grade}
				touched={formik.touched.high_school_grade}
				value={formik.values.high_school_grade}
				onChange={formik.handleChange}
				onBlur={formik.handleBlur}
			/>
			<InputField
				label="Which school are you considering?"
				id="school_prospect"
				error={formik.errors.school_prospect}
				touched={formik.touched.school_prospect}
				value={formik.values.school_prospect}
				onChange={formik.handleChange}
				onBlur={formik.handleBlur}
			/>
			<div className="py-4 w-full">
				<Button onClick={formik.handleSubmit}>Next</Button>
			</div>
		</div>
	);
}

SignUpForm3.propTypes = {
	formik: PropTypes.object.isRequired,
};
