import RandomAvatar from '../common/Avatar';
import { useAuth } from '../../context/authentication';

export default function DashboardAvatar() {
	const { user } = useAuth();
	return (
		<div className="flex flex-col justify-start items-center space-y-4 w-fill">
			<RandomAvatar size={200} />
			<p className="text-center text-xl">{`${user?.firstName} ${user?.lastName}`}</p>
		</div>
	);
}
