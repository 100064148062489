const data = {
	essay: [
		{
			name: 'Essay 1',
			school_name: 'School 1',
			score: 100,
			progress: 30,
			average: 90.22,
		},
		{
			name: 'Essay 2',
			school_name: 'School 2',
			score: 90,
			progress: 40,
			average: 80.1,
		},
		{
			name: 'Essay 3',
			school_name: 'School 3',
			score: 80,
			progress: 50,
			average: 70.11,
		},
		{
			name: 'Essay 4',
			school_name: 'School 4',
			score: 70,
			progress: 60,
			average: 60.12,
		},
		{
			name: 'Essay 5',
			school_name: 'School 5',
			score: 60,
			progress: 70,
			average: 50.13,
		},
		{
			name: 'Essay 6',
			school_name: 'School 6',
			score: 50,
			progress: 80,
			average: 40.14,
		},
		{
			name: 'Essay 7',
			school_name: 'School 7',
			score: 40,
			progress: 90,
			average: 30.15,
		},
		{
			name: 'Essay 8',
			school_name: 'School 8',
			score: 30,
			progress: 100,
			average: 20.16,
		},
		{
			name: 'Essay 9',
			school_name: 'School 9',
			score: 20,
			progress: 10,
			average: 10.17,
		},
		{
			name: 'Essay 10',
			school_name: 'School 10',
			score: 10,
			progress: 20,
			average: 0.18,
		},
	],
	tasks: [
		{
			name: 'Student 1',
			description: 'Student 1 Description',
			type: 'Type 1',
			created_at: '2022-01-01',
			due_date: '2022-01-02',
		},
		{
			name: 'Student 2',
			description: 'Student 2 Description',
			type: 'Type 2',
			created_at: '2022-01-03',
			due_date: '2022-01-04',
		},
		{
			name: 'Student 3',
			description: 'Student 3 Description',
			type: 'Type 3',
			created_at: '2022-01-05',
			due_date: '2022-01-06',
		},
		{
			name: 'Student 4',
			description: 'Student 4 Description',
			type: 'Type 4',
			created_at: '2022-01-07',
			due_date: '2022-01-08',
		},
		{
			name: 'Student 5',
			description: 'Student 5 Description',
			type: 'Type 5',
			created_at: '2022-01-09',
			due_date: '2022-01-10',
		},
		{
			name: 'Student 6',
			description: 'Student 6 Description',
			type: 'Type 6',
			created_at: '2022-01-11',
			due_date: '2022-01-12',
		},
		{
			name: 'Student 7',
			description: 'Student 7 Description',
			type: 'Type 7',
			created_at: '2022-01-13',
			due_date: '2022-01-14',
		},
		{
			name: 'Student 8',
			description: 'Student 8 Description',
			type: 'Type 8',
			created_at: '2022-01-15',
			due_date: '2022-01-16',
		},
		{
			name: 'Student 9',
			description: 'Student 9 Description',
			type: 'Type 9',
			created_at: '2022-01-17',
			due_date: '2022-01-18',
		},
		{
			name: 'Student 10',
			description: 'Student 10 Description',
			type: 'Type 10',
			created_at: '2022-01-19',
			due_date: '2022-01-04',
		},
	],
	records: [
		{
			name: 'Student 1',
			progress: 30,
			average: 90.22,
		},
		{
			name: 'Student 2',
			progress: 40,
			average: 80.1,
		},
		{
			name: 'Student 3',
			progress: 50,
			average: 70.11,
		},
		{
			name: 'Student 4',
			progress: 60,
			average: 60.12,
		},
		{
			name: 'Student 5',
			progress: 70,
			average: 50.13,
		},
		{
			name: 'Student 6',
			progress: 80,
			average: 40.14,
		},
		{
			name: 'Student 7',
			progress: 90,
			average: 30.15,
		},
		{
			name: 'Student 8',
			progress: 100,
			average: 20.16,
		},
		{
			name: 'Student 9',
			progress: 10,
			average: 10.17,
		},
		{
			name: 'Student 10',
			progress: 20,
			average: 0.18,
		},
		{
			name: 'Student 11',
			progress: 30,
			average: 90.22,
		},
		{
			name: 'Student 12',
			progress: 40,
			average: 80.1,
		},
		{
			name: 'Student 13',
			progress: 50,
			average: 70.11,
		},
		{
			name: 'Student 14',
			progress: 60,
			average: 60.12,
		},
		{
			name: 'Student 15',
			progress: 70,
			average: 50.13,
		},
		{
			name: 'Student 16',
			progress: 80,
			average: 40.14,
		},
		{
			name: 'Student 17',
			progress: 90,
			average: 30.15,
		},
		{
			name: 'Student 18',
			progress: 100,
			average: 20.16,
		},
		{
			name: 'Student 19',
			progress: 10,
			average: 10.17,
		},
		{
			name: 'Student 20',
			progress: 20,
			average: 0.18,
		},
		{
			name: 'Student 21',
			progress: 30,
			average: 90.22,
		},
		{
			name: 'Student 22',
			progress: 40,
			average: 80.1,
		},
		{
			name: 'Student 23',
			progress: 50,
			average: 70.11,
		},
		{
			name: 'Student 24',
			progress: 60,
			average: 60.12,
		},
		{
			name: 'Student 25',
			progress: 70,
			average: 50.13,
		},
		{
			name: 'Student 26',
			progress: 80,
			average: 40.14,
		},
		{
			name: 'Student 27',
			progress: 90,
			average: 30.15,
		},
		{
			name: 'Student 28',
			progress: 100,
			average: 20.16,
		},
		{
			name: 'Student 29',
			progress: 10,
			average: 10.17,
		},
		{
			name: 'Student 30',
			progress: 20,
			average: 0.18,
		},
		{
			name: 'Student 31',
			progress: 30,
			average: 90.22,
		},
		{
			name: 'Student 32',
			progress: 40,
			average: 80.1,
		},
		{
			name: 'Student 33',
			progress: 50,
			average: 70.11,
		},
		{
			name: 'Student 34',
			progress: 60,
			average: 60.12,
		},
		{
			name: 'Student 35',
			progress: 70,
			average: 50.13,
		},
		{
			name: 'Student 36',
			progress: 80,
			average: 40.14,
		},
		{
			name: 'Student 37',
			progress: 90,
			average: 30.15,
		},
		{
			name: 'Student 38',
			progress: 100,
			average: 20.16,
		},
		{
			name: 'Student 39',
			progress: 10,
			average: 10.17,
		},
		{
			name: 'Student 40',
			progress: 20,
			average: 0.18,
		},
		{
			name: 'Student 41',
			progress: 30,
			average: 90.22,
		},
		{
			name: 'Student 42',
			progress: 40,
			average: 80.1,
		},
		{
			name: 'Student 43',
			progress: 50,
			average: 70.11,
		},
		{
			name: 'Student 44',
			progress: 60,
			average: 60.12,
		},
		{
			name: 'Student 45',
			progress: 70,
			average: 50.13,
		},
		{
			name: 'Student 46',
			progress: 80,
			average: 40.14,
		},
	],
};

export default data;
