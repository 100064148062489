import PropTypes from 'prop-types';
import InputField from '../../common/form/InputField';
import Button from '../../common/Button';
import SelectInputField from '../../common/form/SelectInputField';
import { HIGHEST_LEVEL_OF_EDUCATION } from '../signup.resources';

export default function SignUpForm2({ formik }) {
	return (
		<div className="py-10 w-full lg:max-w-sm flex flex-col justify-start items-center space-y-[1rem]">
			<InputField
				label="Nationality"
				id="nationality"
				error={formik.errors.nationality}
				touched={formik.touched.nationality}
				value={formik.values.nationality}
				onChange={formik.handleChange}
				onBlur={formik.handleBlur}
			/>
			<InputField
				label="Planned field of study"
				id="field_of_study"
				error={formik.errors.field_of_study}
				touched={formik.touched.field_of_study}
				value={formik.values.field_of_study}
				onChange={formik.handleChange}
				onBlur={formik.handleBlur}
			/>
			<InputField
				label="Level you are applying for"
				id="current_level"
				error={formik.errors.current_level}
				touched={formik.touched.current_level}
				value={formik.values.current_level}
				onChange={formik.handleChange}
				onBlur={formik.handleBlur}
			/>
			<SelectInputField
				label="Highest level of education"
				id="highest_level"
				options={HIGHEST_LEVEL_OF_EDUCATION}
				error={formik.errors.highest_level}
				touched={formik.touched.highest_level}
				value={formik.values.highest_level}
				onChange={formik.handleChange}
				onBlur={formik.handleBlur}
			/>
			<div className="py-4 w-full">
				<Button onClick={formik.handleSubmit}>Next</Button>
			</div>
		</div>
	);
}

SignUpForm2.propTypes = {
	formik: PropTypes.object.isRequired,
};
