import React from 'react';
import PropTypes from 'prop-types';

export default function Button({ children, onClick, disabled }) {
	return (
		<button
			disabled={disabled}
			type="button"
			className={`w-full px-[1rem] rounded-[0.5rem] flex-shrink-0 flex justify-center items-center ${disabled && 'opacity-40'} bg-[#4B1D8F] h-[3.12rem] text-white text-[1rem] font-medium`}
			onClick={onClick}
		>
			{children}
		</button>
	);
}

Button.defaultProps = {
	disabled: false,
};

Button.propTypes = {
	children: PropTypes.node.isRequired,
	onClick: PropTypes.func.isRequired,
};
