import React from 'react';
import { CiSearch } from 'react-icons/ci';

export default function Search({ ...props }) {
	return (
		<div className="w-full flex flex-row justify-between items-center px-[1rem] h-[3.25rem] py-[0.56rem] space-y-[0.37rem] rounded-[0.5rem] border-[1px] focus-within:ring-[1px] group-focus-within:ring-purple-900 border-slate-200 focus-within:outline-none bg-slate-100">
			<input
				type="text"
				className="w-[90%] h-full text-[0.875rem] font-normal placeholder-slate-500  border-none outline-none bg-slate-100"
				{...props}
			/>
			<CiSearch size="2rem" color="#64748B" />
		</div>
	);
}
