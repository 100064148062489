import React from 'react';

export default function ResetPasswordHeader() {
	return (
		<div className="w-full flex flex-col justify-start items-center space-y-4 pt-16">
			<h1 className="text-purple-900 text-4xl text-center font-semibold">
				Reset Password
			</h1>
		</div>
	);
}
