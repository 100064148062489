import useSignUp from '../../hooks/useSignUp';
import SignUpInfo from './signup.info';
import SignUpHeader from './signup.header';
import SignUpForm1 from './forms/signup.form1';
import { Link } from 'react-router-dom';
import SignUpForm2 from './forms/signup.form2';
import SignUpForm3 from './forms/signup.form3';
import SignUpForm4 from './forms/signup.form4';

export function SignUpView() {
	const {
		currentStep,
		setCurrentStep,
		formik1,
		formik2,
		formik3,
		formik4,
		getCurrentFormik,
		isSubmitting,
	} = useSignUp();

	const FORM_TO_DISPLAY = {
		1: <SignUpForm1 formik={formik1} />,
		2: <SignUpForm2 formik={formik2} />,
		3: <SignUpForm3 formik={formik3} />,
		4: <SignUpForm4 formik={formik4} isSubmitting={isSubmitting} />,
	};

	return (
		<div className="w-full h-full flex lg:flex-row justify-center lg:justify-start items-center">
			<div className="hidden lg:block w-1/2 h-full">
				<SignUpInfo />
			</div>
			<div className="lg:w-1/2 md:w-2/3 w-full h-full px-4 flex flex-col justify-center items-center p-10 overflow-y-scroll">
				<SignUpHeader
					currentStep={currentStep}
					setCurrentStep={setCurrentStep}
					formik={getCurrentFormik()}
				/>
				{FORM_TO_DISPLAY[currentStep]}
				<p className="text-sm text-purple-900">
					Already have an account?{' '}
					<Link to="/sign-in" className="text-yellow-400">
						Sign in
					</Link>
				</p>
			</div>
		</div>
	);
}
