import PropTypes from 'prop-types';

export default function ScoreCard({ type, color, evaluation }) {
	const { title, description, total, completed } = evaluation;

	const completionRate = completed / total;

	return (
		<div
			className="w-full flex flex-col justify-start items-start rounded-[0.5rem] space-y-6 p-6"
			style={{ backgroundColor: `${color}25` }}
		>
			<h1 className="text-2xl text-left font-medium">{title}</h1>
			<div className="w-full flex flex-col justify-start items-center space-y-4">
				<p className="text-left text-4xl font-semibold">
					{type === 'percentage' ? `${completed}%` : `${completed}/${total}`}
				</p>
				<p className="text-left text-sm">{description}</p>
			</div>
			<div className="w-full h-6" style={{ backgroundColor: `${color}60` }}>
				<div
					className="h-full"
					style={{
						width: `${completionRate * 100}%`,
						backgroundColor: `${color}`,
					}}
				/>
			</div>
		</div>
	);
}

ScoreCard.defaultProps = {
	type: 'rate',
};

ScoreCard.propTypes = {
	type: PropTypes.string,
	color: PropTypes.string.isRequired,
	evaluation: PropTypes.object.isRequired,
};
