import axios from 'axios';
import { customAxios, encryptUserToken } from '../utils/custom-axios';

const BASE_URL = process.env.REACT_APP_BACKEND_URL;

// Create a new user account
export const register = async (userData, userType) => {
	try {
		return await axios.post(`${BASE_URL}/users/${userType}`, userData);
	} catch (error) {
		throw error;
	}
};

// Get token information only
export const fetchToken = async (username, password) => {
	try {
		return await axios.post(`${BASE_URL}/auth/authenticate`, {
			username,
			password,
		});
	} catch (error) {
		throw error;
	}
};

export const login = async (username, password) => {
	try {
		const response = await axios.post(`${BASE_URL}/auth/user-login`, {
			username,
			password,
		});

		if (response?.status !== 200) {
			throw new Error('Login failed');
		}

		customAxios.defaults.headers.common.Authorization = `Bearer ${response?.data?.jwt}`;
		localStorage.setItem('accessToken', encryptUserToken(response?.data?.jwt));
		localStorage.setItem(
			'refreshToken',
			encryptUserToken(response?.data?.refreshToken)
		);
		localStorage.setItem('user', JSON.stringify(response?.data?.user));

		return response;
	} catch (error) {
		console.error('Login error:', error);
		throw error;
	}
};

export const forgetPassword = async (username) => {
	try {
		return await axios.post(`${BASE_URL}/auth/reset/send/${username}`);
	} catch (error) {
		throw error;
	}
};

export const resetPassword = async (token, password) => {
	try {
		return await axios.post(`${BASE_URL}/auth/reset/forgot`, {
			password,
			token,
		});
	} catch (error) {
		throw error;
	}
};

export const validateToken = async (token) => {
	try {
		return await axios.post(`${BASE_URL}/auth/reset/validate`, {
			token,
		});
	} catch (error) {
		throw error;
	}
};

export const resetPasswordForLoggedInUser = async (
	username,
	newPassword,
	oldPassword
) => {
	try {
		return await customAxios.post(`${BASE_URL}/users/reset/logged-in`, {
			username,
			newPassword,
			oldPassword,
		});
	} catch (error) {
		throw error;
	}
};
