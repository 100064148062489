import ScoreCard from '../common/ScoreCard';

export default function DashboardScorecards({ data }) {
	const { evaluation } = data;
	const COLORS = ['#00FF00', '#CCCC00', '#FFA500'];
	return (
		<div className="flex flex-col lg:flex-row justify-start lg:justify-between items-center lg:items-start w-full lg:space-x-8 space-y-8 lg:space-y-0">
			{evaluation.map((e, index) => (
				<ScoreCard
					evaluation={e}
					color={COLORS[index]}
					key={e.title}
					type={index === 2 ? 'percentage' : 'rate'}
				/>
			))}
		</div>
	);
}
