import LayoutView from '../layout/layout.view';
import useResetPasswordLoggedInUser from '../../hooks/useResetPasswordLoggedInUser';
import SettingsResetPassword from './settings.reset-password';

export default function SettingsView() {
	const { formik, isSubmitting } = useResetPasswordLoggedInUser();
	return (
		<LayoutView>
			<div className="flex flex-col w-full justify-start items-start h-full mt-10 lg:mt-0 bg-white p-4 lg:p-10 mt-8 lg:mt-0">
				<h1 className="text-2xl lg:text-3xl font-semibold text-left">
					Settings
				</h1>
				<div className="w-full h-full flex flex-col justify-start items-center space-y-10 overflow-y-scroll">
					<SettingsResetPassword formik={formik} isSubmitting={isSubmitting} />
				</div>
			</div>
		</LayoutView>
	);
}
